
import { Options, Vue } from 'vue-class-component'
import { intersection, pick } from 'lodash'
import { WordCategory } from '@/services/interfaces/api.service.interfaces'
import api from '@/services/api.service'
import {
  DICTIONARY_CATEGORY_EDIT_AMOUNT_STEP,
  DICTIONARY_CATEGORY_MIN_WORDS_AMOUNT,
  TRANSLATION_DIRECTIONS,
  WORDS_AMOUNT_DEFAULT,
  WORDS_AMOUNT_MAX,
  WORDS_AMOUNT_MIN,
} from '@/config'
import {
  TrainingSettingsDirection,
  TrainingSettingsDictionaryCategories,
  TrainingSettingsWordsAmount, TrainingSettingsSound, TrainingSettingsExamples,
} from '@/components/interfaces/training-settings.interfaces'
import DefaultState from '@/store/default/store'
import { DefaultStateSettingsForUpdate } from '@/store/default/interfaces'

@Options({
  computed: {
    chosenCategoriesId() { return this.getChosenCategoriesId() },
    totalWords() {
      return this.dictionaryCategories.raw
        .reduce((count: number, c: WordCategory) => count + c.quantity, 0)
    },
    totalWordsChosen() {
      return this.getChosenCategoriesId()
        .reduce((count: number, key: string) => count + this.dictionaryCategories.amounts[key], 0)
    },
  },
  methods: {
    getCategoryLabel(category: WordCategory): string {
      return `${category.title} (${category.quantity})`
    },
  },
  watch: {
    isModalVisible(is: boolean) {
      if (!is) {
        this.updateSettings()
        setTimeout(() => this.$refs.settingsButton.$el.blur(), 10)
      }
    },
  },
})
export default class TrainingSettings extends Vue {
  private isMaximumWordAmountsInstalled = false
  private state = DefaultState;
  private isModalVisible = false;
  $refs!: {
    settingsButton: HTMLFormElement
  };

  // private settingsButton = ref<HTMLButtonElement>()
  private dictionaryCategories: TrainingSettingsDictionaryCategories = {
    raw: [],
    chosen: {},
    amounts: {},
    settings: {
      minWordsAmount: DICTIONARY_CATEGORY_MIN_WORDS_AMOUNT,
      editingStep: DICTIONARY_CATEGORY_EDIT_AMOUNT_STEP,
    },
  }

  private wordsAmount: TrainingSettingsWordsAmount = {
    min: WORDS_AMOUNT_MIN,
    max: WORDS_AMOUNT_MAX,
    value: WORDS_AMOUNT_DEFAULT,
  }

  private direction: TrainingSettingsDirection = {
    value: TRANSLATION_DIRECTIONS[2],
    options: TRANSLATION_DIRECTIONS,
  }

  private sound: TrainingSettingsSound = {
    onFailedAttempt: true,
    onSucceedAttempt: true,
  }

  private examples: TrainingSettingsExamples = {
    displayOnQuestFinish: true,
  }

  async created(): Promise<void> {
    this.dictionaryCategories.raw = await api.getWordCategories()
    this.dictionaryCategories.raw.forEach((category: WordCategory) => {
      const min = DICTIONARY_CATEGORY_MIN_WORDS_AMOUNT
      this.dictionaryCategories.chosen[category.id] = true
      this.dictionaryCategories.amounts[category.id] = category.quantity < min
        ? category.quantity : min
    })
    this.updateSettings()
  }

  showModal(): void {
    this.isModalVisible = true
  }

  hideModal(): void {
    this.isModalVisible = false
  }

  getChosenCategoriesId(): number[] {
    return Object
      .keys(this.dictionaryCategories.chosen)
      .map((value) => parseInt(value, 10))
      .filter((value) => this.dictionaryCategories.chosen[value] === true)
  }

  getChosenCategoriesWordsAmounts(): { [key: number]: number } {
    const chosenCategoriesId = this.getChosenCategoriesId()
    const allCategoriesId = Object.keys(this.dictionaryCategories.amounts)
      .map((categoryId) => parseInt(categoryId, 10))
    const excludeCategoriesId = intersection(allCategoriesId, chosenCategoriesId)

    return pick(this.dictionaryCategories.amounts, excludeCategoriesId)
  }

  changeWordAmountsLimits(): void {
    Object.keys(this.dictionaryCategories.chosen)
      .map((categoryId) => parseInt(categoryId, 10))
      .forEach((categoryId) => {
        const min = DICTIONARY_CATEGORY_MIN_WORDS_AMOUNT
        const quantity = this.dictionaryCategories
          .raw
          .find((category) => category.id === categoryId)?.quantity
          ?? min

        if (this.isMaximumWordAmountsInstalled) {
          this.dictionaryCategories.amounts[categoryId] = quantity < min ? quantity : min
        } else {
          this.dictionaryCategories.amounts[categoryId] = quantity
            ?? min
        }
      })

    this.isMaximumWordAmountsInstalled = !this.isMaximumWordAmountsInstalled
  }

  updateSettings(): void {
    this.state.dispatch('updateSettings', {
      direction: this.direction.value,
      sound: this.sound,
      examples: this.examples,
      wordsAmount: this.wordsAmount.value,
      categoriesAmounts: this.getChosenCategoriesWordsAmounts(),
      categoriesChosen: this.getChosenCategoriesId(),
    } as DefaultStateSettingsForUpdate)
  }
}
