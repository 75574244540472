import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { Word, WordCategory } from '@/services/interfaces/api.service.interfaces'

class Api {
  private client: AxiosInstance | null

  constructor() {
    this.client = axios.create({
      baseURL: 'https://eestikeel.hq.ee/',
      timeout: 2000,
    })
  }

  async getWords(
    quantity: number,
    chosenCategoriesHash: string,
  ): Promise<Word[]> {
    const response: AxiosResponse<Word[]> | undefined = await this.client?.get(`/randomword?quantity=${quantity}&chosen-categories=${chosenCategoriesHash}`)
    return response?.data as unknown as Word[]
  }

  async getWordCategories(): Promise<WordCategory[]> {
    const response: AxiosResponse<WordCategory[]> | undefined = await this.client?.get('/dictionary/word-categories')
    return response?.data as unknown as WordCategory[]
  }
}

export default new Api()
