
import { Options, Vue } from 'vue-class-component'
import WordTraining from '@/components/WordTraining.vue'
import TrainingSettings from '@/components/TrainingSettings.vue'

@Options({
  emits: ['on-word-changed', 'on-enter-pressed'],
  components: {
    WordTraining,
    TrainingSettings,
  },
})
export default class Home extends Vue {
  private chosenWordNumber = 0;
  private enterButtonPressedCount = 0;

  created(): void {
    window.addEventListener('keyup', (event) => {
      const activeElementName = ((document.activeElement as Element)?.tagName ?? '').toLowerCase()

      if (activeElementName !== 'input') {
        this.onKeyPressed(event)
      }
    })
  }

  onWordChanged(): void {
    this.chosenWordNumber = 0
  }

  onKeyPressed(event: KeyboardEvent): void {
    const keyAsNumber = parseInt(event.key, 10)
    const isWordChosen = keyAsNumber >= 1 && keyAsNumber <= 9

    if (isWordChosen) {
      this.chosenWordNumber = keyAsNumber
    } else if (event.key === 'Enter') {
      this.enterButtonPressedCount += 1
      this.chosenWordNumber = 0
    }
  }
}
