import { createStore } from 'vuex'
import { DefaultState, DefaultStateSettingsForUpdate } from '@/store/default/interfaces'

export default createStore({
  state: {
    settings: null,
  } as DefaultState,
  mutations: {
    updateSettings(state: DefaultState, settings: DefaultStateSettingsForUpdate) {
      state.settings = settings
    },
  },
  actions: {
    updateSettings({ commit }, settings: DefaultStateSettingsForUpdate) {
      commit('updateSettings', settings)
    },
  },
})
