
import { Options, prop, Vue } from 'vue-class-component'
import { Word } from '@/services/interfaces/api.service.interfaces'

class WordExamplesProps {
  words = prop({
    type: Object,
    default: null,
    required: true,
  });
}

@Options({
})
export default class WordExamples extends Vue.with(WordExamplesProps) {
  words!: Word[]
}
