
import { Options, prop, Vue } from 'vue-class-component'
import {
  isNull, omitBy, pick, random, round, shuffle,
} from 'lodash'
import { mapState } from 'vuex'
import api from '@/services/api.service'
import { Word } from '@/services/interfaces/api.service.interfaces'
import { TranslationDirection, WORDS_AMOUNT_DEFAULT } from '@/config'
import { DefaultStateSettingsForUpdate } from '@/store/default/interfaces'
import { isFalsy } from '@/util'
import WordExamples from '@/components/WordExamples.vue'

class WordTrainingProps {
  chosenWordNumber = prop({
    type: Number,
    default: null,
    required: true,
  });

  enterButtonPressedCount = prop({
    type: Number,
    default: 0,
    required: true,
  });
}

@Options({
  components: { WordExamples },
  computed: {
    ...mapState(['settings']),
    getTotalAttempts() {
      return this.stats.attemptsSucceeded + this.stats.attemptsFailed
    },
    getSuccessRatio() {
      if (this.getTotalAttempts === 0) {
        return 0
      }

      const ratio = (this.stats.attemptsSucceeded * 100) / this.getTotalAttempts

      return Number.isNaN(ratio) ? 0 : round(ratio, 2)
    },
    isExamplesDisplayed(): boolean {
      if (isNull(this.settings)) {
        return false
      }

      return this.settings.examples.displayOnQuestFinish ? this.isQuestFinished : true
    },
  },
  watch: {
    async chosenWordNumber(newValue) {
      if (newValue > 0 && newValue <= this.settings?.wordsAmount) {
        await this.onWordChosen(newValue)
      }
    },
    async enterButtonPressedCount() { await this.onEnterPressed() },
    async settings(
      newValue: DefaultStateSettingsForUpdate | null,
      oldValue: DefaultStateSettingsForUpdate | null,
    ) {
      if (!isNull(newValue) && isNull(oldValue)) {
        this.loadQuest()
      }
    },
  },
})

export default class WordTraining extends Vue.with(WordTrainingProps) {
  readonly SOUND_SUCCESS = new Audio('/sounds/attempt_succeed.mp3');
  readonly SOUND_FAILURE = new Audio('/sounds/attempt_failed.mp3');
  private stats: {
    attemptsSucceeded: number;
    attemptsFailed: number;
    isCurrentWordFailed: boolean;
  } = {
    attemptsSucceeded: 0,
    attemptsFailed: 0,
    isCurrentWordFailed: false,
  };

  private settings!: DefaultStateSettingsForUpdate | null
  private words: Word[] = [];
  private randomWord: Word | null = null;
  private failedWordsNumbers: number[] = [];
  private isQuestFinished = false;
  private isQuestFinishedWithSuccess = false;
  $refs!: {
    LoadingBarStartButton: HTMLFormElement,
    LoadingBarFinishButton: HTMLFormElement
  };

  private isLoadingErrorVisible = false

  async loadQuest(): Promise<void> {
    this.$refs.LoadingBarStartButton.$el.click()
    try {
      this.words = await api.getWords(
        this.settings?.wordsAmount ?? WORDS_AMOUNT_DEFAULT,
        btoa(JSON.stringify(pick(
          this.settings?.categoriesAmounts ?? {},
          Object.values(omitBy(this.settings?.categoriesChosen ?? {}, isFalsy)),
        ))),
      )
    } catch (err) {
      console.error(err)
      this.isLoadingErrorVisible = true
    }
    this.words = this.prepareWords(this.words)
    this.randomWord = shuffle(this.words)[0] ?? null
    this.$refs.LoadingBarFinishButton.$el.click()
  }

  prepareWords(words: Word[], direction: number | null = null): Word[] {
    switch (direction ?? this.settings?.direction.value) {
      case TranslationDirection.FromRussianToEstonian:
        return words
      case TranslationDirection.FromEstonianToRussian:
        return words.map((word) => Object.assign(word, {
          word: word.translation,
          translation: word.word,
        }))
      case TranslationDirection.Random:
        return this.prepareWords(words, random(
          TranslationDirection.FromRussianToEstonian,
          TranslationDirection.FromEstonianToRussian,
        ))
      default:
        return words
    }
  }

  isAnswerCorrect(wordNumber: number): boolean {
    return this.words[wordNumber - 1].translation === this.randomWord?.translation
  }

  isWordAlreadyAttempted(wordNumber: number): boolean {
    return this.failedWordsNumbers.includes(wordNumber)
  }

  async onSuccess(): Promise<boolean> {
    if (this.isQuestFinished) {
      return false
    }

    if (!this.stats.isCurrentWordFailed) {
      this.stats.attemptsSucceeded += 1
    }
    this.stats.isCurrentWordFailed = false
    this.isQuestFinished = true
    this.isQuestFinishedWithSuccess = true
    if (this.settings?.sound.onSucceedAttempt) {
      this.SOUND_SUCCESS.play()
    }

    return true
  }

  onFail(wordNumber: number): boolean {
    if (this.isQuestFinished) {
      return false
    }

    this.failedWordsNumbers.push(wordNumber)
    if (!this.stats.isCurrentWordFailed) {
      this.stats.attemptsFailed += 1
    }
    this.stats.isCurrentWordFailed = true
    if (this.settings?.sound.onFailedAttempt) {
      this.SOUND_FAILURE.play()
    }

    return true
  }

  async onWordChosen(wordNumber: number): Promise<void> {
    if (this.isAnswerCorrect(wordNumber)) {
      await this.onSuccess()
    } else {
      this.onFail(wordNumber)
    }
  }

  async onEnterPressed(): Promise<boolean> {
    if (!this.isQuestFinished) {
      return false
    }
    await this.loadQuest()
    this.isQuestFinished = false
    this.isQuestFinishedWithSuccess = false
    this.clearAttemptedWords()
    this.$emit('on-word-changed')

    return true
  }

  clearAttemptedWords(): void {
    this.failedWordsNumbers = []
  }

  getWordColor(wordNumber: number): string | null {
    if (this.isAnswerCorrect(wordNumber) && this.isQuestFinished) {
      return 'success'
    }
    if (this.isWordAlreadyAttempted(wordNumber)) {
      return 'danger'
    }

    return null
  }
}
