import {
  TrainingSettingsTranslationDirectionOption,
} from '@/components/interfaces/training-settings.interfaces'

export enum TranslationDirection {
  FromRussianToEstonian = 1,
  FromEstonianToRussian = 2,
  Random = 3
}

export const WORDS_AMOUNT_MIN = 2
export const WORDS_AMOUNT_MAX = 9
export const WORDS_AMOUNT_DEFAULT = 5
export const DICTIONARY_CATEGORY_MIN_WORDS_AMOUNT = 100
export const DICTIONARY_CATEGORY_EDIT_AMOUNT_STEP = 50
export const TRANSLATION_DIRECTIONS: TrainingSettingsTranslationDirectionOption[] = [{
  value: TranslationDirection.FromRussianToEstonian,
  name: 'С эстонского на русский',
}, {
  value: TranslationDirection.FromEstonianToRussian,
  name: 'С русского на эстонский',
}, {
  value: TranslationDirection.Random,
  name: 'Наугад',
}]
