import Equal from 'equal-vue'
import 'equal-vue/dist/style.css'
import { createApp } from 'vue'
import DefaultStore from './store/default/store'
import App from './App.vue'
import router from './router'

createApp(App)
  .use(router)
  .use(Equal)
  .use(DefaultStore)
  .mount('#app')
